import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    list: [],
    loading: true,
    operators: {
      query: "",
      operators: {
        data: [],
        total: 0,
        count: 50,
        page: 1,
        loading: true,
      },
    }
  },
  actions,
  mutations,
  getters: {
    getQueryOperators(state) {
      return state.operators.query;
    },
  },
};
