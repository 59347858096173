class SerialNumbersService {
    constructor(http) {
      this.http = http;
    }

    create(newSerialNumber) {
      return this.http.post(`/admin/cameras/serial-numbers`, newSerialNumber);
    } 
}
export default SerialNumbersService;
  