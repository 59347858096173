import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    list: [],
    report: []
  },
  actions,
  mutations,
};
