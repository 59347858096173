export const keysLower = (data) => {
  let result;

  if (!(data instanceof Object)) return data;

  data instanceof Array ? (result = []) : (result = {});

  for (let item in data) {
    result[item.toLowerCase()] = keysLower(data[item]);
  }

  return result;
};
