import { ApiService } from "@/api";

export default {
  GET_MODELS({ commit }, payload) {
    return ApiService.models
      .getList({
        search: encodeURI(payload.query) || "",
        page: payload.page,
        count: payload.count,
      })
      .then((data) => {
        commit("SET_LIST_MODELS", data);
        return data;
      });
  },
  GET_MODEL(context, id) {
    return ApiService.models.get(id);
  },
  UPDATE_MODEL(context, payload) {
    return ApiService.models.update(payload.id, payload.data);
  },
  ADD_MODEL (context, payload) {
    return ApiService.models.create(payload)
      .then(response => response.data.id_model);
  },
  REMOVE_MODEL(context, id) {
    return ApiService.models.delete(id);
  },
  GET_MODELS_APPEND({ commit }, payload) {
    return ApiService.models
      .getList({
        search: encodeURI(payload.query) || "",
        page: payload.page,
        count: payload.count,
      })
      .then((response) => {
        commit("SET_LIST_MODELS_APPEND", response.data);
        return response.data;
      });
  },
  GET_MODEL_CONFIG_TEMPLATE(context, id) {
    return ApiService.models.getConfigTemplate(id);
  },
  SET_MODEL_CONFIG_TEMPLATE(context, payload) {
    return ApiService.models.setConfigTemplate(payload.id, payload.data);
  },
  GET_CAMERAS_CONFIG_PARAMETERS() {
    return ApiService.models.getConfigParameters();
  },
  GET_MODEL_PARAMETERS(context, id) {
    return ApiService.models.getModelParameters(id);
  },
  SET_MODEL_PARAMETERS(context, payload) {
    return ApiService.models.setModelParameters(payload.id, payload.data);
  },
};
