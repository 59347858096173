import actions from './actions'
import mutations from './mutations'
import { pagination } from '@/store/helpers'

export default {
  namespaced: true,
  state: {
    query: '',
    models: {
      ...pagination
    }
  },
  actions,
  getters: {
    getQuery (state) {
      return state.query;
    }
  },
  mutations
}
