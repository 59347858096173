export default {
  SET_QUERY(state, payload) {
    state.query = payload;
  },
  SET_LIST_MODELS(state, payload) {
    state.models.data = payload.data;
    state.models.total = payload.total;
    state.models.count = payload.count;
  },
  SET_LIST_MODELS_APPEND(state, payload) {
    const items = state.models.data;
    state.models.data = items.concat(payload.data);
  },
}