import { ApiService } from '@/api'

export default {
  GET_LIST({ commit }) {
    commit("SET_LOADING_LIST", true);
    return ApiService.m2m
      .getList()
      .then((res) => {
        commit("SET_LIST", res.data);
        return res.data;
      })
      .finally(() => {
        commit("SET_LOADING_LIST", false);
      });
  },
  ADD_M2M(context, payload) {
    return ApiService.m2m.addM2m(payload);
  },
  REMOVE_M2M(context, payload) {
    return ApiService.m2m.removeM2m(payload);
  },
  ADD_M2M_CLIENTS_IP(context, payload) {
    return ApiService.m2m.addM2mClientsIp(payload);
  },
  REMOVE_M2M_CLIENTS_IP(context, payload) {
    return ApiService.m2m.removeM2mClientsIp(payload);
  },
  GET_OPERATORS({ commit }, payload) {
    commit("SET_LOADING_OPERATORS", true);
    return ApiService.operators
      .getList({
        search: encodeURI(payload.query) || "",
        page: payload.page,
        count: payload.count,
      })
      .then((res) => {
        commit("SET_LIST_OPERATORS", res.data);
        return res.data;
      })
      .finally(() => {
        commit("SET_LOADING_OPERATORS", false);
      });
  },
  ADD_M2M_OPERATOR(context, payload) {
    return ApiService.m2m.addM2mOperator(payload);
  },
};
