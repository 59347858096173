export default {
  SET_LIST_USERS(state, payload) {
    state.data = payload.data;
    state.total = payload.total;
    state.count = payload.count;
  },
  SET_LOGS(state, payload) {
    state.logs.data = payload.data;
    state.logs.total = payload.total;
    state.logs.count = payload.count;
  },
  SET_QUERY(state, payload) {
    state.query = payload;
  },
  SET_QUERY_LOGS(state, payload) {
    state.queryLogs = payload;
  },
};
