import actions from "@/core/components/SelectModelSidetask/store/actions";
import mutations from "@/core/components/SelectModelSidetask/store/mutations";

export default {
  namespaced: true,
  state: {
    sideTaskTypes: null,
  },
  actions,
  mutations,
  getters: {
    getSideTaskTypes(state) {
      return state.sideTaskTypes;
    },
  },
};
