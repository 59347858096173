class DvrServerService {
  constructor(http) {
    this.http = http;
  }

  getList(params = { search: "", page: 1, count: 10 }) {
    return this.http.get(`/admin/dvr-servers`, { headers: params });
  }
}

export default DvrServerService;
