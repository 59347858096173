class M2mService {
  constructor(http) {
    this.http = http;
  }

  getList() {
    return this.http.get(`/admin/m2m`);
  }

  addM2m(payload) {
    return this.http.post(`/admin/m2m`, payload);
  }

  removeM2m(idM2mClient) {
    return this.http.delete(`/admin/m2m/${idM2mClient}`);
  }

  addM2mClientsIp(payload) {
    return this.http.post(`admin/m2m/${payload.id}/ip_address`, payload.data);
  }

  removeM2mClientsIp(payload) {
    return this.http.delete(`admin/m2m/${payload.id}/ip_address`, { data: payload.data });
  }

  addM2mOperator(payload) {
    return this.http.put(`admin/m2m/${payload.id}/operator`, payload.data);
  }
}

export default M2mService;
