import actions from "./actions";
import mutations from "./mutations";
import { pagination } from "@/store/helpers";

export default {
  namespaced: true,
  state: {
    query: "",
    dvrServers: {
      ...pagination,
    },
  },
  actions,
  mutations,
  getters: {
    getQuery(state) {
      return state.query;
    },
  },
};
