import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    profile: null,
    loading: false,
  },
  actions,
  mutations,
};
