import { keysLower } from "../../core/helpers";

class ModelsService {
  constructor(http) {
    this.http = http;
  }

  getList(params = { search: "", page: 1, count: 10 }) {
    return this.http.get(`/admin/cameras/models`, { headers: params }).then((resp) => keysLower(resp.data));
  }

  get(id) {
    return this.http.get(`/admin/cameras/models/${id}`);
  }

  create(newModel) {
    return this.http.post(`/admin/cameras/models`, newModel);
  }

  update(id, newModel) {
    return this.http.put(`/admin/cameras/models/${id}`, newModel);
  }

  delete(id) {
    return this.http.delete(`/admin/cameras/models/${id}`);
  }

  getConfigTemplate(id) {
    return this.http.get(`/admin/cameras/models/${id}/config_template`);
  }

  setConfigTemplate(id, newConfig) {
    return this.http.put(`/admin/cameras/models/${id}/config_template`, newConfig);
  }

  getConfigParameters() {
    return this.http.get(`/admin/cameras/config-parameters`);
  }

  getModelParameters(id) {
    return this.http.get(`/admin/cameras/models/${id}/parameters`);
  }

  setModelParameters(id, newParameters) {
    return this.http.put(`/admin/cameras/models/${id}/parameters`, newParameters);
  }
}
export default ModelsService;
