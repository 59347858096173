class ServerService {
  constructor(http) {
    this.http = http;
  }

  getList(params = { search: "", page: 1, count: 10 }) {
    return this.http.get(`/admin/proxy-servers`, { headers: params });
  }

  create(newServer) {
    return this.http.post(`/admin/proxy-servers`, newServer);
  }

  get(id) {
    return this.http.get(`/admin/proxy-servers/${id}`);
  }

  update(id, newServer) {
    return this.http.put(`/admin/proxy-servers/${id}`, newServer);
  }

  getServerGroups() {
    return this.http.get(`/admin/proxy-servers-groups`);
  }
}

export default ServerService;
