export default {
  SET_OPERATOR(state, payload) {
    state.operator = payload;
  },
  SET_LIST_OPERATORS(state, payload) {
    state.operators.data = payload.data;
    state.operators.total = payload.total;
    state.operators.count = payload.count;
    // state.operators.page = payload.page;
  },
  SET_LIST_OPERATORS_APPEND(state, payload) {
    const items = state.operators.data;
    state.operators.data = items.concat(payload.data);
  },
  SET_QUERY(state, payload) {
    state.query = payload;
  },
};
