import actions from "./actions";
import mutations from "./mutations";
import { getToken } from "./helpers";

export default {
  namespaced: true,
  state: {
    token: getToken(),
  },
  getters: {
    LOGGED_IN(state) {
      return state.token !== "" && state.token !== null && state.token !== "undefined";
    },
  },
  actions,
  mutations,
};
