class ReserveTransfersService {
  constructor(http) {
    this.http = http;
  }

  getList() {
    return this.http.get(`/admin/reserve-transfers`);
  }

  getReport(id) {
    return this.http.get(`/admin/reserve-transfers/${id}`);
  }

  rebootCameras(id) {
    return this.http.put(`/admin/reserve-transfers/${id}/reboot-cameras`);
  }
}

export default ReserveTransfersService;
