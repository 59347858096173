class OperatorsService {
  constructor(http) {
    this.http = http;
  }

  getList(params = { search: "", page: 1, count: 10 }) {
    return this.http.get(`/admin/operators`, { headers: params });
  }

  get(id) {
    return this.http.get(`/admin/operators/${id}`);
  }

  create(newOperator) {
    return this.http.post(`/admin/operators`, newOperator);
  }

  update(id, newOperator) {
    return this.http.put(`/admin/operators/${id}`, newOperator);
  }

  delete(id) {
    return this.http.delete(`/admin/operators/${id}`);
  }

  switchStatus(id, status) {
    return this.http.put(`/admin/operators/${id}/status`, status);
  }
}

export default OperatorsService;
