import Vue from "vue";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/ru-RU";
import "element-ui/lib/theme-chalk/index.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
library.add(faCheck, faTimes);
Vue.component("fa", FontAwesomeIcon);
Vue.use(VueCookies);
Vue.$cookies.config('1d', null, process.env.VUE_APP_COOKIES_DOMAIN);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
