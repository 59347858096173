export default {
  SET_LOADING_LIST(state, payload) {
    state.loading = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload
  },
  SET_QUERY_OPERATORS(state, payload) {
    state.operators.query = payload;
  },
  SET_LOADING_OPERATORS(state, payload) {
    state.operators.operators.loading = payload;
  },
  SET_LIST_OPERATORS(state, payload) {
    state.operators.operators.data = payload.data;
    state.operators.operators.total = payload.total;
    state.operators.operators.count = payload.count;
  },
}
