export default {
  SET_SERVER(state, payload) {
    state.server = payload;
  },
  SET_LIST_SERVERS(state, payload) {
    state.servers.data = payload.data;
    state.servers.total = payload.total;
    state.servers.count = payload.count;
  },
  SET_LIST_OPERATORS(state, payload) {
    state.operators = payload;
  },
  SET_QUERY(state, payload) {
    state.query = payload;
  },
};
