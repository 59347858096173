import { http, setToken } from "@/api";
import { keysLower } from "@/core/helpers";

const guid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0;
      let v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .toUpperCase();
};

export default {
  GET_TOKEN({ commit }, form) {
    return http
      .post("/auth/login", {
        login: form.login,
        password: form.password,
        // operator_short_name: "TestOperator2",
        id_device: guid(),
        id_platform: 3,
      })
      .then((response) => keysLower(response.data))
      .then((data) => {
        commit("SET_TOKEN", data.token);
        localStorage.setItem("token", data.token);
        setToken(data.token);
      });
  },
  REFRESH_TOKEN({ commit }) {
    return http
      .get("/auth/refresh/token")
      .then((response) => keysLower(response.data))
      .then((data) => {
        commit("SET_TOKEN", data.new_token);
        localStorage.removeItem("token");
        localStorage.setItem("token", data.new_token);
        setToken(data.new_token);
      });
  },
};
