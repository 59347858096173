import { keysLower } from "../../core/helpers";

class UserService {
  constructor(http) {
    this.http = http;
  }

  getList(params = { search: "", page: 1, count: 10 }) {
    return this.http.get(`/admin/ad/users`, { headers: params });
  }

  getLogs(params = { search: "", page: 1, count: 10 }) {
    params.search = encodeURI(params.search) || "";
    return this.http.get(`/admin/ad/logs`, { headers: params }).then((resp) => keysLower(resp.data));
  }
}

export default UserService;
