import { ApiService } from "@/api";

export default {
  GET_SERVERS({ commit }, payload) {
    return ApiService.servers
      .getList({
        search: encodeURI(payload.query) || "",
        page: payload.page,
        count: payload.count,
      })
      .then((res) => {
        commit("SET_LIST_SERVERS", res.data);
        return res.data;
      });
  },
  GET_SERVER({ commit }, id) {
    return ApiService.servers.get(id).then((res) => {
      commit("SET_SERVER", res.data);
      commit("SET_LIST_OPERATORS", res.data.operators);
      return res.data;
    });
  },
  UPDATE_SERVER(context, { id, newServer }) {
    return ApiService.servers.update(id, newServer);
  },
  GET_SERVER_GROUPS() {
    return ApiService.servers.getServerGroups().then((res) => {
      return res.data;
    });
  },
};
