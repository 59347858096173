class SideTaskTypesService {
  constructor(http) {
    this.http = http;
  }

  fetchList() {
    return this.http.get("/admin/cameras/models/sidetask-types");
  }
}

export default SideTaskTypesService;
