import { setToken } from "@/api";

export const getToken = () => {
  let token = localStorage.getItem("token");

  if (token) {
    if (token === "undefined") {
      return null;
    } else if (token !== "" && token !== null) {
      setToken(token);
      return token;
    }
  } else {
    return null;
  }
};
