import { ApiService } from "@/api";

export default {
  GET_OPERATORS({ commit }, payload) {
    return ApiService.operators
      .getList({
        search: encodeURI(payload.query) || "",
        page: payload.page,
        count: payload.count,
      })
      .then((res) => {
        commit("SET_LIST_OPERATORS", res.data);
        return res.data;
      });
  },
  GET_OPERATOR({ commit }, id) {
    return ApiService.operators.get(id).then((res) => {
      res.data.several_orgs = res.data.several_orgs || 0;
      commit("SET_OPERATOR", res.data);
      return res.data;
    });
  },
  DELETE_OPERATOR(context, id) {
    return ApiService.operators.delete(id);
  },
  UPDATE_OPERATOR(context, { id, newOperator }) {
    return ApiService.operators.update(id, newOperator);
  },
  SWITCH_STATUS(context, { id, status }) {
    return ApiService.operators.switchStatus(id, { status });
  },
};
