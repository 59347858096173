import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    query: "",
    operator: {},
    operators: {
      data: [],
      total: 0,
      count: 50,
      page: 1,
    },
  },
  actions,
  mutations,
  getters: {
    getQuery(state) {
      return state.query;
    },
  },
};
