import { http } from "@/api";
import { keysLower } from "@/core/helpers";
import router from "@/router";

export default {
  GET_PROFILE({ commit }) {
    commit("SET_PROFILE_LOADING", true);
    return http
      .get("/account")
      .then((response) => keysLower(response.data))
      .then(async (data) => {
        let rules = [];

        if (data.is_admin) {
          rules = [{ actions: "crud", subject: "all" }];
        } else {
          const permissions = await http.get("/account/permissions").then((resp) => keysLower(resp.data));
          rules = permissions.map((permission) => {
            const oRule = {};
            oRule.subject = permission.entity.replace("operator_", "");
            oRule.actions = permission.actions;
            return oRule;
          });
        }

        commit("SET_PROFILE", { data, rules });
        // OperatorService.setOperatorId(data.id_operator);
        return data;
      })
      .catch(() => {
        localStorage.clear();
        router.replace({ name: "login" });
      })
      .finally(() => {
        commit("SET_PROFILE_LOADING", false);
      });
  },
};
