import { ApiService } from "@/api";

export default {
  GET_LIST_USERS({ commit }, payload) {
    return ApiService.users
      .getList({
        search: encodeURI(payload.query) || "",
        page: payload.page,
        count: payload.count,
      })
      .then((response) => commit("SET_LIST_USERS", response.data));
  },
};
